/**
 * File: tagmanager.js
 * File Created: Friday, 6th September 2019 3:31:16 pm
 * Author: Sacha Cochin (sacha.cochin@gridpocket.com)
 * -----
 * Last Modified: Wed May 10 2023
 * Modified By: Sacha Cochin
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */


const key = document.querySelector('meta[name="gtm-key"]').getAttribute('content');

((w, d, s, l, i) => {
  w[l] = w[l] || []; w[l].push({
    'gtm.start':
      new Date().getTime(), event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', key);
